@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #16161A;
  display: flex;
  align-items: center;
  justify-content: center;
}
video,  iframe{
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
  margin-top: 20px;
  margin-bottom: 24px;
}

/* Responsive styles for small screens */
@media (max-width: 768px) {
  .card {
    width: 312px;
    height: 312px;
  }
}

/* Responsive styles for bigger screens */
@media (min-width: 769px) {
  .card {
    width: 400px;
    height: 400px;
  }
}


.button-primary{
  display: flex;
  flex-direction: row;
  gap: 4px;
  box-shadow: 0px 10px 30px rgba(75, 75, 234, 0.2);
  border-radius: 100px;
  /* background-color: #7F5AF0; */
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100%;
   height: 70px;
  font-Family: "Satoshi" ;
}
.container {
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100%;
  max-width: 401px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.7);
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14.7692px 0px 0px;
  gap: 29.54px;
  /* width: 360px; */
  width: 401px;
  height: 330.08px;
  background: #FFFFFF;
  border-radius: 22.1538px 22.1538px 0px 0px;
}
@media screen and (min-width: 768px) {

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.70);
  z-index: 9999;
}

.container {
  width: 100%;
  max-width: 401px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14.7692px 0px 0px;
  gap: 29.54px;
  width: 360px;
  height: 330.08px;
  background: #ffffff;
  border-radius: 22.1538px;
}
}

.transparent-button{
  box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 14.7692px 0px;
gap: 7.38px;

width: 301.85px;
height: 51.69px;

/* Neutral/40 */

border: 0.923077px solid #DBDBDB;
filter: drop-shadow(0px 5.53846px 69.2308px rgba(100, 87, 87, 0.15));
border-radius: 92.3077px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.sticky-bar-wrapper{
  margin-top: 90px;
}

.sticky-bar-content{
  background-color: #000;
}

.sticky-bar-loader {
	border-top-color: rgb(109, 107, 107);
  margin-right: 10px;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/*Share Modal*/

.copy-share-link{
  margin-top: 40px;
}

.share-link-span{
  display: inline-block;
  padding: 12px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #383838;
}

.share-link-copy-btn{
  display: inline-block;
  padding: 12px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  background-color: #4e4e4e;
}

.share-btn-list{
  margin-top: 40px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-btn-list span{
  margin-right:20px;
}

.ellipse{
width: 72px;
height: 72px;
border-radius: 50%;
left: 32.5px;
background-color: rgba(246, 246, 254, 0.1);
}

.reward-box{
width: 289px;
/* height: 136px; */
padding: 24px;
border-radius: 12px;
gap: 16px;
background-color: #242629;
}